import React from 'react';
import { collection,getDocs, query} from 'firebase/firestore';
import { db } from '../../../firebase';
import Youtube from './Youtube';
const Videos = () => {
    const [videos, setvideo] = React.useState([]);
    const [isloading, setIsloading] = React.useState(true);
    React.useEffect( () => {
        getvideo();
    }, []);
    const getvideo = async() => {
        const docRef =  collection(db,'webtv');
        const q = query(docRef);
        const querySnapshot = await getDocs(q);
        const dataArr =[];
        querySnapshot.forEach((res) => {
            const { name, link,date} = res.data();
            dataArr.push({
                id : res.id,
                name,
                link,
                date,
            });
        });
        setvideo(dataArr)
        setIsloading(false)
    }  
    if (isloading) {
        return (
            <div >
                chargement
            </div>
        );
    }else {
    return (
        <div className='videos'>
            {
                videos.map((video) => (
                    <div key={video.id}>
                        <Youtube  embed={video.link} describe={video.name} boxstyle={"video-cart"} Date={video.date}/>
                    </div>
                ))
            }
        </div>
    );
    }
};

export default Videos;