
import { Routes,Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import DetailProducts from './pages/DetailProducts';
import Hommes from './pages/Category/Hommes';
import Femmes from './pages/Category/Femmes';
import Enfants from './pages/Category/Enfants';
import ProductsSubCat from './components/ProductsSubCat';
import Products from './pages/Products';


function App() {
  return (
    <div >
      <Routes>
              <Route exact path='/' element={<Home/>}/>
              <Route path="/products/enfants" element={<Enfants/>}/>
              <Route path="/products" element={<Products/>}/>
              <Route path="/products/femmes" element={<Femmes/>}/>
              <Route path="/products/hommes" element={<Hommes/>}/>
              <Route path="/details-produit/:ref" element={<DetailProducts/>}/>
              <Route path="/liste-produits/:ref" element={<ProductsSubCat/>}/>

      </Routes>
    </div>
  );
}

export default App;
