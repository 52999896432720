import React from "react";
import PropTypes from "prop-types";
const Youtube = ({ embed, titre, boxstyle,describe, Date }) => (
  <div className={boxstyle}>

      <h2 className="titre-video"> {titre}</h2>
    <iframe
      width="853"
      height="480"
      src={embed}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
    <p className="datevideo">{Date}</p>
    <p className="description-video">{describe}</p>
  </div>
);

Youtube.propTypes = {
  embed: PropTypes.string.isRequired
};

export default Youtube;