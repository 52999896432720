import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { BsCartPlus } from "react-icons/bs";
import { FaWhatsapp, FaInstagram, FaYoutube } from "react-icons/fa";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const BootstrapButton = styled(Button)({
  background: "#dcb253",
  marginBottom: 10,
  "&:hover": {
    backgroundColor: "#795d1c",
    borderColor: "#795d1c",
  },
});
const DetailsProduitsList = ({ name, price, attribut }) => {
  const prix = price;
  const [scrolledUp, setScrolledUp] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [valeur, setValeur] = useState(prix);
  const [filtrer,setFiltrer] = useState(documents);
  const handleChange = (event) => {
    setValeur(event.target.value);
  };
  useEffect(() => {
    setFiltrer(documents.filter(attrib => attribut.includes(attrib.id)));
  }, [documents, attribut]);
  React.useEffect(() => {
    getAttributes();
  }, []);
  const getAttributes = async () => {
    const docRef = collection(db, "attribute");
    const q = query(docRef);
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((res) => {
      const { name, value } = res.data();
      dataArr.push({
        id: res.id,
        name,
        value,
      });
    });
    setDocuments(dataArr);
  };
  useEffect(() => {
    if (!scrolledUp) {
      window.scrollTo(0, 0);
      setScrolledUp(true);
    }
  }, [scrolledUp]);
  return (
    <div>
      <div className="detail-txt-prod">
        <h1>{name}</h1>
        <h2>{valeur} CFA</h2>
      </div>

      <div className="box-btn-detail-product"></div>
      <br />
      <div>
          {
            filtrer.map((attribut)=> (
              <div  key={attribut.id}>
                
              <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{attribut.name}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={valeur}
                  label={attribut.name}
                  onChange={handleChange}
                >
                  {
                  attribut.value.map((valeurs)=>(
                    
                    <MenuItem value={valeurs.valeur} key={valeurs.name}>{valeurs.name} = {valeurs.valeur} CFA</MenuItem>
                    
                  ))
                  }
                </Select>
              </FormControl>
            </Box>
            <br/>
              </div>
              ))
          }
      </div>
      <br />
      <div className="box-btn-detail-product">
        <BootstrapButton variant="contained">
          <BsCartPlus />{" "}
          <span className="txt-btn-detail-products">AJOUTER AU PANIER</span>
        </BootstrapButton>
        <BootstrapButton variant="contained">
          <BsCartPlus />{" "}
          <span className="txt-btn-detail-products">AJOUTER AU FAVORIS</span>
        </BootstrapButton>
      </div>
      <br />
      <div>
        <h5>Shopping Info</h5>
        <p>100% Produits Original</p>
        <p>paiement securisé en ligne avec plusieurs moyens</p>
      </div>
      <div>
        <h5>Partager sur les reseaux</h5>
        <div className="reseau-social">
          <a href="whatsapp.com">
            <FaWhatsapp className="couleur" />
          </a>
          <a href="instagram.com">
            <FaInstagram className="couleur" />
          </a>
          <a href="youtube.com">
            <FaYoutube className="couleur" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DetailsProduitsList;
