import React from 'react';
import Youtube from './Youtube';
import { collection,getDocs, query} from 'firebase/firestore';
import { db } from '../../../firebase';

const Video = () => {
    const [documents, setDocuments] = React.useState([]);
    const [isloading, setIsloading] = React.useState(true);
    React.useEffect( () => {
        getvideo();
    }, []);
    const getvideo = async() => {
        const docRef =  collection(db,'video');
        const q = query(docRef);
        const querySnapshot = await getDocs(q);
        const dataArr =[];
        querySnapshot.forEach((res) => {
            const { name, lien} = res.data();
            dataArr.push({
                id : res.id,
                name,
                lien,
            });
        });
        setDocuments(dataArr)
        setIsloading(false)
        
    }
    if (isloading) {
        return (
            <div className="video-responsive">
                chargement
            </div>
        );
    }else {
        return (
            <div>
                {
                    documents.map((video) => (
                        <div key={video.id}>
                            <Youtube  embed={video.lien} titre={video.name} boxstyle={"video-responsive"}/>
                        </div>
                    ))
                }
            </div>
        );
    }
};

export default Video;