import React, { useEffect, useState } from 'react'
import Entete from '../components/Entete';
import Headgen from '../components/header/Headgen';
import NavBar from '../components/header/NavBar';
import TopBar from '../components/header/TopBar';
import { doc,getDoc} from 'firebase/firestore';
import { db } from '../firebase';
import { useParams } from 'react-router-dom'
import Footer from '../components/Footer/Footer';
import Product from '../components/product/Product';
import DetailsProduitsList from '../components/product/DetailsProduitsList';
import DetailText from '../components/product/DetailText';
const DetailProducts = () => {
    const [articles,setArticles] = useState([]);
  useEffect(() => {
    
    getArticles();
  })
  let { ref } = useParams();
  const [params, setParams] = useState(ref);
  useEffect(() => {
    setParams(ref);
  }, [ref]);

  useEffect(() => {
    // Save the params in local storage when the URL parameter changes
    localStorage.setItem('params', JSON.stringify(params));
  }, [params]);

  useEffect(() => {
    // Get the params from local storage on page reload
    const storedParams = JSON.parse(localStorage.getItem('params'));
    if (storedParams) {
      setParams(storedParams);
    }
  }, []);
  const getArticles = async()=>{
    const docRef = doc(db, "products", params);
    const docSnap = await getDoc(docRef);
    setArticles(docSnap.data());
  }  
    return (
        <div>
            <TopBar/>
            <NavBar/>
            <Headgen text="Retour au Magasin" lien="/products" />
            <Entete  entete="Détails article" titre={articles.name} />
            <div className='box-produits-detail'> 
            <Product name={articles.name} image={articles.image} describe={articles.describe} price={articles.price}/>
            <DetailsProduitsList name={articles.name} price={articles.price} attribut={articles.attribute}/>
            </div>
            <DetailText describe={articles.describe}/>
            <br/>
            <Footer/>
        </div>
    );
};

export default DetailProducts;