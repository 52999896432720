import React from 'react';
import Body from '../components/body/Body';
import Footer from '../components/Footer/Footer';
import Header from '../components/header/Header';

const Home = () => {
    return (
        <div>
            <Header/>
            <Body/>
            <Footer/>
        </div>
    );
};

export default Home;