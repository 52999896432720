import React from 'react';
import { Link } from 'react-router-dom';
const Categories = () => {
    return (
        <div className='box-cat'>
                <div className='box-cat__box'>
                    <div className='box-cate'>
                        <img src="https://firebasestorage.googleapis.com/v0/b/nappylocks-56e13.appspot.com/o/image%2Fcategory%2Fhomme.jpg?alt=media&token=a9085678-cf8b-4523-97c6-2816062c7c23" alt="HOMME"/>
                        <div className='box-cat--titre'>
                            <p>HOMME</p>

                            <Link to={`/products/hommes`}>
                            <button type="button"> VOIR +</button>
                            </Link>
                        </div>
                    </div>
                    <div className='box-cate'>
                        <img src="https://firebasestorage.googleapis.com/v0/b/nappylocks-56e13.appspot.com/o/image%2Fcategory%2FFEMME.jpg?alt=media&token=d3fb6081-169f-470b-8fb3-ef9e22ea07a0" alt="FEMME"/>
                        <div className='box-cat--titre'>
                            <p>FEMME</p>
                            <Link to="/products/femmes">
                            <button type="button"> VOIR +</button>
                            </Link>
                        </div>
                    </div>
                    <div className='box-cate'>
                        <img src="https://firebasestorage.googleapis.com/v0/b/nappylocks-56e13.appspot.com/o/image%2Fcategory%2FENFANT.jpg?alt=media&token=a229d3c3-40c8-4eb4-8eaf-21910265cfe1" alt="ENFANTS"/>
                        <div className='box-cat--titre'>
                            <p>ENFANT</p>
                            <Link to="/products/enfants">
                            <button type="button"> VOIR +</button>
                            </Link>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default Categories;