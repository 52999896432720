import React from 'react';

const SlydeProducts = ({image, name,describe,price}) => {
      return (
        <div>
            <div>
                <img src={image} alt={name}  className="image-details-produits"/>
            </div>
        </div>
      );
    }

export default SlydeProducts;