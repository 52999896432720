import React, { useState, useEffect } from 'react';
import { FaSearch } from "react-icons/fa";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Avatar } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import { collection, query, where,orderBy,onSnapshot } from "firebase/firestore";
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
export default function Search() {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [searchValue, setSearchValue] = useState('');
  const [products, setProducts] = useState(null);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && event.key === 'Escape') {
      setState({ ...state, [anchor]: false });
      return;
    }
  
    setState({ ...state, [anchor]: open });
  };
  
  useEffect(() => {
    if (searchValue === "") {
      setProducts(null);
      return;
    }

    const q = query(
      collection(db, "products"),
      where("name", ">=", searchValue),
      orderBy("name")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(data);
    });

    return unsubscribe;
}, [searchValue]);


  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400 }}
      role="presentation"
    >
      <List>
        <ListItem>
          <Typography variant="h6" component="div" sx={{color : "#dcb253", textAlign:'center', }}>
            Recherche
          </Typography>
        </ListItem>
        <ListItem>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={9}>
              <TextField 
                id="search" 
                label="Rechercher" 
                fullWidth 
                value={searchValue} 
                onChange={(e) => setSearchValue(e.target.value.toLowerCase())} 
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button 
                variant="contained" 
                color="primary" 
                fullWidth 
                onClick={() => setSearchValue(searchValue.toLowerCase())}
                sx={{background : '#dcb253', height : 55}}
              >
               <FaSearch/>
              </Button>
            </Grid>
          </Grid>
        </ListItem>
      </List>
      <Divider />
      {products !== null ? (
  <List>
    {products.map((product) => (
      <Link to={`/details-produit/${product.id}`}>
      <ListItem key={product.id} disablePadding>
      <ListItemButton>
        <Avatar
          sx={{ height: 70, width: 70 }}
          alt={product.name}
          src={product.image}
        />
        
        <ListItemText primary={product.name} sx={{ marginLeft: 1, color : "black" }} />
      </ListItemButton>
  </ListItem>
      </Link>
    ))}
  </List>
) : (
  <Typography sx={{textAlign: 'center'}}>Aucun produits</Typography>
)}
    </Box>
  );
  
  return (
    <div>
      <Button onClick={toggleDrawer("right", true)}>
        <FaSearch />
      </Button>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </div>
  );
}
