import React from 'react';
import { Link,useLocation } from 'react-router-dom';
const NavBar = () => {
    const location = useLocation();
    return (
        <div className='navbar'>
            <div>
                <img src="/img/logo.png" alt="logo"  className='logo'/>
            </div>
            <div className='barre'>
                
            </div>
            <div className='navlink'>
            <Link to="/" className={location.pathname === "/" ? 'link active' : 'link'} >Accueil</Link>
            <Link to="/Products" className={location.pathname === "/Products" ? 'link active' : 'link'} >Produits</Link>
            <Link to="/galerie" className={location.pathname === "/galerie" ? 'link active' : 'link'} >Galerie</Link>
            <Link to="/about" className={location.pathname === "/about" ? 'link active' : 'link'} >A propos</Link>
            <Link to="/contacts" className={location.pathname === "/contacts" ? 'link active' : 'link'} >Contacts</Link>
            </div>
        </div>
    );
};

export default NavBar;