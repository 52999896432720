import React from 'react';
import Entete from '../Entete';
import Activite from './Activite';
import Services from './Services';
import Slyder from './Slyder';
import Categories from './Categories';
import ProductsList from './ProductsList';
import Video from './webtv/Video';
import Videos from './webtv/Videos';
import NosEquipes from './NosEquipes';
const Body = () => {
    return (
        <div>
            <Slyder/>
            <br/>
            <br/>
            <br/>
            <div className='barre'></div>
            <Services/>
            <div className='barre'></div>
            <Entete  entete="Découvrir" titre="NOS SERVICES" />
            <Activite/>
            <Entete  entete="Accès rapide" titre="SELON VOTRE PROFIL" sousTitre="Retrouvez rapidement nos différents modèles selon votre profil."/>
            <Categories/>
            <Entete  entete="Découvrir le" titre="TOP 10 DU MOMENT" sousTitre="Vous recherchez les modèles les plus en vogue du moment ?" sousTitre2={`Bienvenu(e) sur le TOP 10 Du MOMENT`}/>
            <ProductsList />
            <Entete  entete="NappyLocks" titre="WEB TV" sousTitre="Suivez toutes nos dernières vidéos" />
            <Video/>
            <Entete  entete="Autres vidéos à" titre="VISUALISER" />
            <Videos/>
            <br/>
            <Entete  titre="NOTRE ÉQUIPE DE TRAVAIL" />
            <NosEquipes/>

        </div>
    );
};

export default Body;