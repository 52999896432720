import { BsCart3 } from "react-icons/bs";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Avatar } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { FiDelete } from "react-icons/fi";
import '@mui/material/styles';
const BootstrapButton = styled(Button)({
  color: "#dcb253",
  textAlign: "center",
  left: "50%",
  transform: "translateX(-50%)",
});
function Panier() {
  const [countCart, setCountCart] = useState(0);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [panier, setPanier] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDelete = (id) => {
    const newPanier = Panier.filter((item) => item.id !== id);
    setPanier(newPanier);
    localStorage.setItem("panier", JSON.stringify(newPanier));
  };

  useEffect(() => {
    const storedPanier = JSON.parse(localStorage.getItem("panier")) || [];
    setPanier(storedPanier);
    
    const handleStorageChange = () => {
      const updatedPanier = JSON.parse(localStorage.getItem("panier")) || [];
      setPanier(updatedPanier);
    }
  
    window.addEventListener("storage", handleStorageChange);
  
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("panier", JSON.stringify(panier));
  }, [panier]);
  useEffect(() => {
    const panier = JSON.parse(localStorage.getItem("panier"));
    if (panier) {
      setPanier(panier);
      setCountCart(panier.length);
    }
  }, []);
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && event.key === 'Escape') {
      setState({ ...state, [anchor]: false });
      return;
    }
  
    setState({ ...state, [anchor]: open });
  };
  

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400 }}
      role="presentation"
    >
      <List>
        <ListItem>
          <Typography variant="h6" component="div" sx={{color : "#dcb253", textAlign:'center', }}>
            Mon panier
          </Typography>
        </ListItem>

      </List>
      <Divider /> 
      {Panier !== 0 ? (
  <List>
    {panier.map((product) => (
      <Link to={`/details-produit/${product.id}`} key={product.id}>
      <ListItem  disablePadding>
              <ListItemButton>
            <Badge
              badgeContent={product.count}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              color="secondary"
            >
                <Avatar
                  sx={{ height: 70, width: 70 }}
                  alt={product.name}
                  src={product.image}
                />
            </Badge>
                <ListItemText primary={product.name} sx={{ marginLeft: 1 }} />
                <Button size="medium" onClick={() => handleDelete(product.id)}>
                  <FiDelete style={{ color: "#dcb253", fontSize: 20 }} />
                </Button>
              </ListItemButton>
          </ListItem>
      </Link>
    ))}
  <Divider />
  <BootstrapButton size="medium">Valider Mon panier</BootstrapButton>
  </List>
) : (
  <Typography sx={{textAlign: 'center'}}>Aucun produits</Typography>
)}
    </Box>
  );

  return (
    <div>
       <React.Fragment>
        <Button onClick={toggleDrawer("right", true)}>
          <Badge badgeContent={countCart} color="primary">
            <BsCart3 />
          </Badge>
        </Button>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

export default Panier;