import React from 'react';
import SlydeProducts from './SlydeProducts';

const Product = ({name,describe,price,image}) => {
    return (    
        <div>
            <SlydeProducts name={name} image={image} describe={describe} price={price}/>
        </div>
    );
};

export default Product; 