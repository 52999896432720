import React from 'react'
import creation from '../../assets/svg/creation.svg'
import entretien from '../../assets/svg/ENTRETIEN.svg'
import extension from '../../assets/svg/EXTENSION.svg'

function Activite() {
    return (
        <div className='services'>
            <div className='box-services-2'>
                <div className='box-services'>
                    <img src={creation} alt="creation"/>
                    <p>CREATION</p>  
                </div>
                <div className='box-services'>
                    <img src={extension} alt="EXTENSION"/>
                    <p>EXTENTION</p>  
                </div>
                <div className='box-services'>
                    <img src={entretien} alt="ENTRETIEN"/>
                    <p>ENTRETIEN</p>  
                </div>
            </div>
        </div>
    )
}

export default Activite;