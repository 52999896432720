import React from 'react';
import { Link } from 'react-router-dom';
const Headgen = ({lien, text}) => {
    return (
        <div className='headgen'>
            <Link to={lien}><h2 className='headgen-txt'>{text}</h2></Link>
        </div>
    );
};

export default Headgen;