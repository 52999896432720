import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
const SubCategorie = ({name,image}) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: 350,
            height: 300,
          },
        }}
      >
        <Item elevation={1}>
        <Avatar
        alt={name}
        src={image}
        sx={{ width: 200., height: 200, left:"50%", transform:"translateX(-50%)"}}
      />
        <h3 style={{width : "100%", textAlign : "center"}}>{name}</h3>
        </Item>
      </Box>
    </div>
  );
};

export default SubCategorie;
