import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBCol,
  MDBRow,
  MDBBtn
} from 'mdb-react-ui-kit';

export default function Footer() {
  return (
    <MDBFooter className='text-center' color='white' bgColor='dark'>
      <MDBContainer className='p-4'>
        <section className='mb-4'>
          <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <MDBIcon fab icon='facebook-f' />
          </MDBBtn>

          <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <MDBIcon fab icon='twitter' />
          </MDBBtn>

          <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <MDBIcon fab icon='google' />
          </MDBBtn>

          <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <MDBIcon fab icon='instagram' />
          </MDBBtn>

          <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <MDBIcon fab icon='linkedin-in' />
          </MDBBtn>

          <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <MDBIcon fab icon='github' />
          </MDBBtn>
        </section>

        <section className=''>
          <form action=''>
            <MDBRow className='d-flex justify-content-center'>
              <MDBCol size="auto">
                <p className='pt-2'>
                  <strong>Ne manquez rien de NappyLocks</strong>
                </p>
              </MDBCol>

              <MDBCol md='5' start>
                <MDBInput contrast type='email' label='Email address' className='mb-4' />
              </MDBCol>

              <MDBCol size="auto">
                <MDBBtn outline color='light' type='submit' className='mb-4'>
                  S'enregistrer
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </form>
        </section>

        <section className='mb-4'>
          <p>
          A Abidjan, l’institut est présent dans la commune de : <br/>
Cocody précisément à la palmeraie
| A Koumassi remblais
| Au Sénégal, il est présent à Dakar
| Enfin en France à Paris.
          </p>
        </section>

        <section className='block-liens'>
          <MDBRow>
            <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
              <h5 className='text-uppercase'>Mon Compte</h5>

              <ul className='list-unstyled mb-0'>
                <li>
                  <a href='#!' className='text-white'>
                    Se Connecter
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                    Profils
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                    Nouveau Compte
                  </a>
                </li>
                <li>
                  <a  href='#!' className='text-white'>
                  Contacts
                  </a>
                </li>
              </ul>
            </MDBCol>

            <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
              <h5 className='text-uppercase'>Pourquoi Nous Choisir</h5>

              <ul className='list-unstyled mb-0'>
                <li>
                  <a href='#!' className='text-white'>
                  Expédition Et Livraison A La Demande
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                  Service En Ligne Client
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                  Achats Sécurisés
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                  Galerie
                  </a>
                </li>
              </ul>
            </MDBCol>

            <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
              <h5 className='text-uppercase'>Nos applications</h5>

              <ul className='list-unstyled mb-0'>
                <li>
                  <a href='#!' className='text-white'>
                    Android
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                    Ios
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                    Prendre Rendez-vous
                  </a>
                </li>
     
              </ul>
            </MDBCol>

            <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
              <h5 className='text-uppercase'>Information</h5>

              <ul className='list-unstyled mb-0'>
                <li>
                  <a href='#!' className='text-white'>
                  Abidjan, Cocody Riviera Palméraie
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                  +225 07 08 70 45 30

                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                  +225 07 59 16 15 15
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                  Institut@Nappylocks.Com
                  </a>
                </li>
              </ul>
            </MDBCol>
          </MDBRow>
        </section>
      </MDBContainer>

      <div className='text-center p-3 footers' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © 2023 Copyright
        <br/>
        <a className='text-white' href='https://mdbootstrap.com/'>
           Ghossoub | Sunder-Dev
        </a>
      </div>
    </MDBFooter>
  );
}