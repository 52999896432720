import React, { useEffect, useState } from 'react'
import { collection, getDocs, query,where } from "firebase/firestore";
import { db } from '../../firebase';
import Footer from '../../components/Footer/Footer';
import TopBar from '../../components/header/TopBar';
import NavBar from '../../components/header/NavBar';
import Entete from '../../components/Entete';
import Headgen from '../../components/header/Headgen';
import SubCategorie from '../../components/SubCategorie';
import { Link } from 'react-router-dom';
const Femmes = () => {
  const [scrolledUp, setScrolledUp] = useState(false);

  useEffect(() => {
  if (!scrolledUp) {
    window.scrollTo(0, 0);
    setScrolledUp(true);
  }
}, [scrolledUp]);
    const [subCategory,setSubCategory] = useState([]);
    useEffect(() => {
      getCategory();
    })
    const identifiant = "ERtA7ko13paqIB9JdW4y";
    const getCategory = async()=>{
        const docRef = collection(db, "subCategories");
        const q = query(docRef, where("idCategory", "==", identifiant));
        const querySnapshot = await getDocs(q);
        const dataArr = [];
        querySnapshot.forEach((res) => {
          const { idCategory, name} = res.data();
          dataArr.push({
            id: res.id,
            idCategory,
            name,
          });
        });
        setSubCategory(dataArr);
      };
    return (
        <div>
             <TopBar/>
            <NavBar/>
            <Headgen text="Retour au Magasin" lien="/products" />
            <Entete  entete="Categorie" titre="Femmes" />
            <div className="box-subcat">
            {
                subCategory.map((subcat)=>(
                  <Link to={`/liste-produits/${subcat.id}`} >
                  <SubCategorie key={subcat.id} name={subcat.name} image="test"/>
                  </Link>
                ))
            }
            </div>
            <Footer/>
        </div>
    );
};

export default Femmes;