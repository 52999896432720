import React from 'react';
import Slider from 'react-slick';

const Slyder = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3500,
      };
    return (
        <div>
             <div>
        <Slider {...settings}>
          <div>
            <img src="/img/slide1.jpg" alt="slide1" className='slyder-img'/>
          </div>
          <div>
          <img src="/img/slide2.jpg" alt="slide2" className='slyder-img'/>
          </div>
          <div>
          <img src="/img/slide3.JPG" alt="slide3" className='slyder-img'/>
          </div>
          <div>
          <img src="/img/slide4.JPG" alt="slide4" className='slyder-img'/>
          </div>
          <div>
          <img src="/img/slide5.JPG" alt="slide5" className='slyder-img'/>
          </div>
        </Slider>
      </div>
      
        </div>
    );
};

export default Slyder;