import React from 'react';
import {motion} from "framer-motion"

const Entete = ({entete,titre,sousTitre, valeur, sousTitre2}) => {
   
        return (
            <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1}}
            className='box-galerie__header'>
                    <p className='entete'>{entete}</p>
                    <h1 className='titre'>{titre}</h1>
                    <div className='box-bar'>
                    <div className='minibarre'></div>
                    </div>
                    <p className='soustitre'>{sousTitre} <br/ >{sousTitre2}</p>
            </motion.div>
        );

    
   
};

export default Entete;