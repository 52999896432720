import React, { useState } from "react";
import Slider from "react-slick";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
const NosEquipes = () => {
    const [documents, setDocuments] = useState([]);
    React.useEffect(() => {
      getProducts();
    }, []);
  
    const getProducts = async () => {
      const docRef = collection(db, "employer");
      const q = query(docRef);
      const querySnapshot = await getDocs(q);
      const dataArr = [];
      querySnapshot.forEach((res) => {
        const { name, image} = res.data();
        dataArr.push({
          id: res.id,
          name,
          image,
        });
      });
      setDocuments(dataArr);
    };
  var settings = {
    dots: true,
    className: "center",
    centerMode: true,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        {
            documents.map((employer) => (
        <div className="equipe" key={employer.id}>
          <Stack direction="column" spacing={2} className="in-equipe">
            <Avatar
              alt={employer.name}
              src={employer.image}
              sx={{ width: 150, height: 150 }}
            />
            <h3 className="in-equipe-text">{employer.name}</h3>
          </Stack>
        </div>
            ))
        }
      </Slider>
    </div>
  );
};
export default NosEquipes;
