import React from "react";
import { FaWhatsapp, FaUsers } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import Panier from "../Panier";
import Search from "./Search";
export default function TopBar({ countCart }) {
  return (
    <div className="topbar">
      <div className="topgauche">
        <p>Bienvenu(e) sur notre site.</p>
        <p className="invisible"> test</p>
        <p>
          <FaWhatsapp /> +225 07 59 161 515
        </p>
      </div>
      <div className="topdroit">
       {/*  <button type="button">
          <FaUsers />
        </button> */}
        <button type="button">
          <Search/>
        </button>
       {/*  <button type="button">
          <FiSettings />
        </button> */}
        <Panier/>
      </div>
    </div>
  );
}
