import React, { useState, useEffect } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { styled } from "@mui/material/styles";
import { db } from "../../firebase";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { BsCartPlus, BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const BootstrapButton = styled(Button)({
  color: "#dcb253",
});
const ProductsList = () => {
  const [open, setOpen] = React.useState(false);


  const fermenotif = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [documents, setDocuments] = useState([]);
  React.useEffect(() => {
    getProducts();
  }, []);

  const [panier, setPanier] = useState(
    JSON.parse(localStorage.getItem("panier")) || []
  );

  useEffect(() => {
    localStorage.setItem("panier", JSON.stringify(panier));
  }, [panier]);
  const getProducts = async () => {
    const docRef = collection(db, "products");
    const q = query(docRef);
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach((res) => {
      const { name, image, price, attribute } = res.data();
      dataArr.push({
        id: res.id,
        name,
        image,
        attribute,
        price,
      });
    });
    setDocuments(dataArr);
  };

  const getPanier = (nom, image, id) => {
    setPanier((prevPanier) => {
      const existingProduct = prevPanier.find((item) => item.id === id);
      let newPanier;
      if (existingProduct) {
        existingProduct.count += 1;
        newPanier = [...prevPanier];
      } else {
        newPanier = [...prevPanier, { id: id, name: nom, image: image, count: 1 }];
      }
      localStorage.setItem("panier", JSON.stringify(newPanier));
      setOpen(true);
      
      return newPanier;
    });
  };
  return (
    <div className="box-products">
      {documents.slice(0, 10).map((product) => (
        <div key={product.id}>
          <Card sx={{ width: 250, height: 440 }} className="products">
            <Link to={`/details-produit/${product.id}`}>
              <CardMedia
                sx={{ height: 270 }}
                image={product.image}
                title={product.name}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ height: 50, color: "black" }}
                >
                  {product.name}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: 600, color: "black" }}
                >
                  {product.price} CFA
                </Typography>
              </CardContent>
            </Link>
            <CardActions
              sx={{
                height: 0,
                display: "flex",
                justifyContent: "space-around",
              }}
              className="box-button-products"
            >
              <BootstrapButton size="small">
                <BsSearch />{" "}
                <Link to={`/details-produit/${product.id}`}>
                  {" "}
                  <span className="button-product" style={{ color: "#dcb253" }}>
                    aperçu
                  </span>
                </Link>
              </BootstrapButton>
              <BootstrapButton
                size="small"
                onClick={() =>
                  getPanier(product.name, product.image[0], product.id)
                }
              >
                <BsCartPlus /> <span className="button-product">AJOUTER</span>
              </BootstrapButton>
              <Snackbar
                open={open}
                autoHideDuration={1000}
                onClose={fermenotif}
              >
                <Alert
                  onClose={fermenotif}
                  severity="success"
                  sx={{ width: "100%", backgroundColor :"#dcb253", boxShadow : "none"}}
                >
                  Article Ajouté au Panier
                </Alert>
              </Snackbar>
            </CardActions>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default ProductsList;
