import React, { useEffect, useState } from "react";
import Entete from "../components/Entete";
import Headgen from "../components/header/Headgen";
import NavBar from "../components/header/NavBar";
import TopBar from "../components/header/TopBar";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebase";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { BsCartPlus, BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";
const BootstrapButton = styled(Button)({
  color: "#dcb253",
});
const Products = () => {
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    getArticles();
  });
  const getArticles = async () => {
    const docRef = collection(db, "products");
    const q = query(docRef);
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach((res) => {
      const { name, image, price, attribute } = res.data();
      dataArr.push({
        id: res.id,
        name,
        image,
        attribute,
        price,
      });
    });
    setArticles(dataArr);
  };
  return (
    <div>
      <TopBar />
      <NavBar />
      <Headgen text="Retour à l'Accueil" lien="/" />
      <Entete entete="Tous Nos" titre="Produits" />
      <div className="box-products">
        {articles.map((product) => (
          <div key={product.id}>
            <Link to={`/details-produit/${product.id}`}>
              <Card sx={{ width: 250, height: 440 }} className="products">
                <CardMedia
                  sx={{ height: 270 }}
                  image={product.image}
                  title={product.name}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ height: 50 }}
                  >
                    {product.name}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: 600 }}
                  >
                    {product.price} CFA
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    height: 0,
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                  className="box-button-products"
                >
                  <BootstrapButton size="small">
                    <BsSearch /> <span className="button-product">aperçu</span>
                  </BootstrapButton>
                  <BootstrapButton size="small">
                    <BsCartPlus />{" "}
                    <span className="button-product">AJOUTER</span>
                  </BootstrapButton>
                </CardActions>
              </Card>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
