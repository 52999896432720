import React from 'react';
import NavBar from './NavBar';
import TopBar from './TopBar';

const Header = () => {
    return (
        <div>
            <TopBar countCart={0}/>
            <NavBar/>

        </div>
    );
};

export default Header;